<template>
  <div :class="{ 'is-sp': $vuetify.breakpoint.xs }">
    <v-card>
      <v-card-title>詳細（{{ user.name }}）</v-card-title>
      <Loading v-if="isUserLoading" />
      <p v-else-if="user === null">通信エラー</p>
      <v-card-text v-else>
        <section class="user_info">
          <v-card-subtitle>ユーザー情報</v-card-subtitle>
          <ul>
            <li class="mb-5 mr-3">
              <v-avatar size="40px">
                <v-img
                  v-if="!user.img"
                  :src="require('@/assets/images/user.png')"
                ></v-img>
                <v-img
                  v-else-if="$store.state.picture"
                  :src="
                    VUE_APP_BACK_URL + '/storage/images/' + $store.state.picture
                  "
                ></v-img>
                <v-img
                  v-else
                  :src="VUE_APP_BACK_URL + '/storage/images/' + user.img"
                ></v-img>
              </v-avatar>
            </li>
            <li>
              <v-text-field
                label="名前"
                :value="user.name"
                prepend-inner-icon="mdi-account"
                dense
                readonly
                outlined
                color="primary"
                class="mr-2"
              ></v-text-field>
            </li>
            <li>
              <v-text-field
                label="メールアドレス"
                :value="user.email"
                prepend-inner-icon="mdi-email"
                dense
                readonly
                outlined
                color="primary"
                class="mr-2"
              ></v-text-field>
            </li>
            <li style="width: 250px" class="mr-2">
              <v-select
                :value="user.team_id"
                :items="TEAM"
                label="チーム"
                :item-value="id"
                item-text="label"
                prepend-inner-icon="mdi-account-supervisor-circle"
                outlined
                dense
                color="primary"
                readonly
              ></v-select>
            </li>
            <li style="width: 250px">
              <v-select
                :value="user.attribute"
                :items="ATTRIBUTE"
                label="属性"
                :item-value="id"
                item-text="label"
                prepend-inner-icon="mdi-badge-account-horizontal"
                outlined
                dense
                color="primary"
                readonly
              ></v-select>
            </li>
            <v-spacer></v-spacer>
            <li>
              <v-btn
                :disabled="isUserLoading"
                @click="$router.push(`/main/user/update?userId=${user.id}`)"
                color="orange"
                class="orange white--text"
              >
                <span>編集</span>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </li>
          </ul>
        </section>
        <v-divider class="divider"></v-divider>
        <section>
          <v-card-subtitle>タスク情報（{{ user.name }}）</v-card-subtitle>
          <v-card outlined style="border: 1px solid rgba(0, 0, 0, 0.3)">
            <TaskList :tasks="tasks" />
            <Loading v-if="isLoading" />
            <p v-else-if="tasks === null" class="text-center">通信エラー</p>
            <p v-else-if="!tasks.length" class="text-center">
              データはありません
            </p>
            <div v-else>
              <v-divider></v-divider>
              <div class="d-flex justify-center">
                <v-btn
                  v-if="addTaskShow"
                  @click="addTaskRead"
                  text
                  color="primary"
                  class="my-2"
                  >続きを読み込む</v-btn
                >
                <v-btn
                  v-if="closeTaskShow"
                  @click="closeTaskRead"
                  text
                  color="primary"
                  class="my-2"
                  >閉じる</v-btn
                >
              </div>
            </div>
          </v-card>
        </section>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { TEAM } from "@/plugins/const";
import { ATTRIBUTE } from "@/plugins/const";
import Loading from "@/components/Loading.vue";
import { myAxios } from "@/plugins/axios";
import TaskList from "@/components/TaskList.vue";
export default {
  components: {
    Loading,
    TaskList,
  },
  data() {
    return {
      TEAM: TEAM,
      ATTRIBUTE: ATTRIBUTE,
      user: {},
      isLoading: false,
      isUserLoading: false,
      paginate: { currentPage: 1 },
      addTaskShow: true,
      closeTaskShow: false,
      tasks: [],
      VUE_APP_BACK_URL: process.env.VUE_APP_BACK_URL,
    };
  },
  methods: {
    async getUser() {
      this.isUserLoading = true;
      const requestConfig = {
        url: "/user",
        method: "GET",
        params: {
          id: this.$route.query.userId,
        },
      };
      myAxios(requestConfig)
        .then((res) => {
          this.user = res.data;
          this.getTasks();
        })
        .finally(() => {
          this.isUserLoading = false;
        });
    },
    async getTasks() {
      this.isLoading = true;
      const requestConfig = {
        url: "/tasks",
        method: "GET",
        params: {
          page: 1,
          requestedUserId: [this.user.id],
          assignBoolean: 1,
        },
      };
      myAxios(requestConfig)
        .then((res) => {
          this.paginate = res.data.paginate;
          this.tasks = res.data.tasks;
          if (this.paginate.currentPage === this.paginate.lastPage) {
            this.addTaskShow = false;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async addTaskRead() {
      this.isLoading = true;
      const requestConfig = {
        url: "/tasks",
        method: "GET",
        params: {
          page: this.paginate.currentPage + 1,
          requestedUserId: [this.user.id],
          assignBoolean: 1,
        },
      };
      myAxios(requestConfig)
        .then((res) => {
          this.paginate = res.data.paginate;
          this.tasks = this.tasks.concat(res.data.tasks);
          if (this.paginate.currentPage === this.paginate.lastPage) {
            this.addTaskShow = false;
            this.closeTaskShow = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closeTaskRead() {
      this.paginate.currentPage = 1;
      this.tasks.splice(20, this.paginate.total - 20);
      this.addTaskShow = true;
      this.closeTaskShow = false;
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style lang="scss" scoped>
.user_info {
  ul {
    display: flex;
    li {
      display: flex;
    }
  }
}
.is-sp {
  .user_info {
    ul {
      display: block;
      li {
        justify-content: center;
      }
    }
  }
}
.v-card__subtitle {
  padding: 5px 10px 16px;
}
.divider {
  margin: 20px;
}
.mdi-account-supervisor-circle::before {
    font-size: 28px;
}
</style>